import { createSlice } from "@reduxjs/toolkit";


export const initialState = {
  logourl:"",
};

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    logourl: ''
  },
  reducers: {
    setProfile: (state, action) => { state.logourl = action.payload.logourl },
  },
});

export const {
  setProfile,
  
} = profileSlice.actions

export default profileSlice.reducer
