import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import store from "./store";
import history from "./history";
import Layouts from "./layouts";
import { THEME_CONFIG } from "./configs/AppConfig";
import "./lang";
import { AUTH_TOKEN } from "constants/AuthConstant";
import axios from "axios";
import "./index.css"

//import { PersistGate } from 'redux-persist/integration/react'
//import { persistStore } from 'redux-persist'

//let persistor = persistStore(store);

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  useEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token) {
      axios.defaults.headers.common["Authorization"] = `bearer ${token}`;
    }
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <BrowserRouter history={history}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>

        {/* </PersistGate> */}
      </Provider>
    </div>
  );
}

export default App;
