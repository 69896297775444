import rootReducer from './rootReducer';
//import storage from 'redux-persist/lib/storage'
import { configureStore } from '@reduxjs/toolkit';
//import { persistReducer } from 'redux-persist'

const middlewares = [];
// const persistConfig = {
//     key: 'root',
//     storage
// };

//const persistedReducer = persistReducer(persistConfig, rootReducer);
const saveToLocalStorage = (state) => {
	try {
		console.log(state?.mockup?.parentTicketPayload,'1515')
		if(state?.mockup?.parentTicketPayload?.id) {
	  localStorage.setItem('state', JSON.stringify(state));
	}
	} catch (e) {
	  console.error(e);
	}
  };
  
  const loadFromLocalStorage = () => {
	try {
	  const stateStr = localStorage.getItem('state');
	  return stateStr ? JSON.parse(stateStr) : undefined;
	} catch (e) {
	  console.error(e);
	  return undefined;
	}
  };
const persistedStore = loadFromLocalStorage();

const store = configureStore({
	reducer: rootReducer(),
	persistedStore,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		}).concat(middlewares),
	devTools: process.env.NODE_ENV === 'development',
})

store.subscribe(() => {
	saveToLocalStorage(store.getState());
  });

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return false;
	}
	store.asyncReducers[key] = reducer;
	store.replaceReducer(rootReducer(store.asyncReducers));
	return store
}

export default store