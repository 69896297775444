import { createSlice } from "@reduxjs/toolkit";


export const initialState = {
  loading: false,
  childTicket: null,
  parentTicket: null,
  currentTicket: null,
  LastCreatedMockupId: null,
  LastCreatedTicket: null,
};

const mockupSlices = createSlice({
  name: "mockups",
  initialState: {
    childTicket: '',
    parentTicket: '',
    currentTicket: '',
    LastCreatedMockupId: '',
    LastCreatedTicket: '',
    childTicketPayload: [],
    parentTicketPayload: {},
    ticketLoaded: false,
    currentTicketIndex: -1,
    childTicketArray: [],
    isMockupPath: false
  },
  reducers: {
    setChildTicket: (state, action) => { state.childTicket = action.payload.info },
    setTicketLoaded: (state, action) => { state.ticketLoaded = action.payload },
    setParentTicket: (state, action) => { state.parentTicket = action.payload.info },
    setCurrentTicket: (state, action) => { state.currentTicket = action.payload.info },
    setLastCreatedMockupId: (state, action) => { state.LastCreatedMockupId = action.payload.info },
    setLastCreatedTicket: (state, action) => { state.LastCreatedTicket = action.payload.info },
    setchildTicketBulkPayload: (state, action) => { state.childTicketPayload = action.payload },
    setchildTicketPayload: (state, action) => { state.childTicketPayload = {...state.childTicketPayload, ...{[(action.payload?.is_parent_ticket?action.payload?.id:action.payload?.childid)]:action.payload}} },
    setparentTicketPayload: (state, action) => { state.parentTicketPayload = {...state.parentTicketPayload, ...action.payload} },
    setparentTicketPayloadSwitch: (state, action) => { state.parentTicketPayload = {...action.payload} },
    clearTickets: (state, action) => { state.parentTicketPayload = action.payload;state.childTicketArray = [];state.childTicketPayload = [] },
    setcurrentTicketID: (state, action) => { state.currentTicketIndex = action.payload },
    setChildTicketArray: (state, action) => { state.childTicketArray = [...state.childTicketArray,action.payload] },
    setChildTicketBulkArray: (state, action) => { state.childTicketArray = [...action.payload] },
    setIsMockupPath: (state, action) => { state.isMockupPath = action.payload },
  },
});

export const {
  setChildTicket,
  setParentTicket,
  setCurrentTicket,
  setLastCreatedMockupId,
  setLastCreatedTicket,
  setchildTicketPayload,
  setparentTicketPayload,
  setTicketLoaded,
  clearTickets,
  setcurrentTicketID,
  setChildTicketArray,
  setparentTicketPayloadSwitch,
  setchildTicketBulkPayload,
  setChildTicketBulkArray,
  setIsMockupPath,
  currentTicket
} = mockupSlices.actions

export default mockupSlices.reducer
