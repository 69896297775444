import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import mockup from './slices/mockupSlice'
import mockups from './slices/mockupSlices'
import orderSlice from './slices/orderSlice'
import profileSlice from './slices/profileSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        mockup,
        mockups,
        orderSlice,
        profileSlice,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
